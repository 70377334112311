import ahoy from 'ahoy.js'
import { RouteLocationNormalized } from 'vue-router'

export const trackNavigation = (
  to: Pick<RouteLocationNormalized, 'fullPath'>
) => {
  const path = to?.fullPath
  if (path) {
    const redacted = path
      .replace(/\d+/g, '<N>')
      .replace(/\?search=.*/, '?search=<T>')
    ahoy.track(redacted)
  }
}
